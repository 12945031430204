body {
  background-color: grey;
}

h3 {
  color: Black;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 32px;
  font-weight: 700;
  padding-top: 10px;
}

h4 {
  color: Black;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 20px;
  font-weight: 700;
}

a {
  text-decoration: none;
  color: black;
}



.footer {
  margin: auto;
  width: 80%;
  padding: 10px;
}
.celname {
  text-align: center;
  color: black;
  font-family: 'Roboto Condensed', sans-serif;
  text-decoration: none;
}

div.thumb {
  padding: 10px;
  margin: 0px auto;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

.legaltext {
  color: Black;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 10;
  font-weight: 400;
}

.detail-box {
  margin: 25px;
  border-style: solid;
  border-width: 10px;
  border-radius: 25px;
  background-color:white;
  min-height: 400px;;
}

.container {
  display: flex;
  margin: 10px;
}

.left-column {
  flex: 2;
  border-style: solid;
  border-width: 10px;
  border-radius: 25px;
  background-color:white;
  padding: 10px;
}

.right-column {
  flex: 1;
  margin-left:10px;
  border-style: solid;
  border-width: 10px;
  border-radius: 25px;
  background-color:white;
  padding: 10px;
}

.product-row {
  margin: 10px;
  padding-bottom: 10px;
}


.column {
  float: left;
  padding: 10px;
}

.left {
  width: 300px;
}

.right {
  width: 50%;
  padding-left:30px;
}
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}

.newsbox {
  clear: left;
  min-height: 100px;
  padding-bottom: 50px;
}

.news-body {
  padding: 10px;
}

.news-date {
  padding-right: 20px;
}

/* Clear floats after the columns */
.product-row:after {
  content: "";
  display: table;
  clear: both;
}

.where-image {
  width: 200px;
  margin: 10px;
  border-radius: 20%;
  border: 1px solid black;
}

.buy-image {
  margin: 10px;
  border: 1px solid black;
}



ul.gallery {
  margin: 0px;
  margin-top: 10px;
  padding: 0px;
  display: block;
}

li.gallerybox {
  vertical-align: top;
  display: inline-block;
  margin: 0px;
}

li.gallerybox-resources {
  vertical-align: top;
  display: inline-block;
  margin: 0px;
}

.mw-gallery-traditional {
  text-align: center;
  }
  
  .page-nav {
    margin: 15px 0 0 0 ;
    border-radius: 0;
    display: inline-block;
    padding-left: 0;
  }
  
  .page-nav li a {
      position: relative;
      float: left;
      display: block;
      width: 40px;
      height: 40px;
      font-size: 13px;
      line-height: 40px;
      padding: 0;
      text-align: center;
      background-color: #f5f5f5;
      color: #333333;
  }
  
  .set-name {
    margin-top: 10px;
    font-size:medium;
  }
  .dropdown-item {
    font-size:1em;
    line-height: 90%;
  }
  
  .navbar-nav {
    line-height: 100%;
  }


  @media all and (min-width: 800px) {
    .navbar .nav-item .dropdown-menu{ display: none; }
    .navbar .nav-item:hover .nav-link{  }
    .navbar .nav-item:hover .dropdown-menu{ display: block; }
    .navbar .nav-item .dropdown-menu{ margin-top:0; }
  }
  

/* grid container */
.right-sidebar-grid {
  display:grid;
  grid-template-areas:
      'main-content'
      'right-sidebar';
}

/* general column padding */
.right-sidebar-grid > * {
  padding:1rem;
}

/* assign columns to grid areas */

.right-sidebar-grid > .main-content {
  grid-area:main-content;
  background:#fff;
}
.right-sidebar-grid > .right-sidebar {
  grid-area:right-sidebar;
  background:#cdcbc7;
}


/* tablet breakpoint */
@media (min-width:768px) {
  .right-sidebar-grid {
      grid-template-columns:repeat(3, 1fr);
      grid-template-areas:
          'header header header'
          'main-content main-content right-sidebar'
          'footer footer footer';
  }
}

.home-property {
  width: 400px;
}

.cellist-image {
  width: 300px;
}

.detail-image {
  width: 300px;
  margin: 10px;
}

.product-image {
  width: 300px;
  border-radius: 10%;
  border: 1px solid black;
}

.news-image {
  width: 300px;
}

.news-home-image {
  width: 150px;
  padding-right: 10px;
}

@media screen and (max-width:500px) {
  .home-property {
    width: 200px;
  }
  .cellist-image {
    width: 200px;
  }
  .detail-image {
    width: 200px;
    margin: 5px;
  }
  .product-image {
    width: 200px;
    border-radius: 10%;
    border: 1px solid black;
  }
  .news-image {
    width: 200px;
  }
}

